@import "styles/configuration/*"

@import "getbasis/src/css/basis"

@import "styles/helper/*"
@import "styles/base/*"
@import "styles/component/*"
@import "styles/instance/*"
@import "styles/layout/*"
@import "styles/utility/*"

#PortfolioItem--SWTD .PortfolioItem-Image
  position: relative
  top: 1em

#projects
  .Portfolio
    margin-top: 1em

.Contact-Link
  color: $color--white
  background-color: transparentify($color--black, .75)
  padding: 0.5em 1em

  @media $media.min-md
    font-size: 1.4rem
